<template>
  <div class="flex justify-center">
    <div class="w-80 mt-16">
      <h1 class="text-4xl font-light">Change Password</h1>
      <h4 class="text-base pt-2 pb-16 font-light">
        Your Password is Very Precious Make it sceret..
      </h4>
      <b-field label="New Password">
        <b-input
          type="Password"
          placeholder="Password"
          icon-pack="far"
          v-model="newPassword"
          password-reveal
        />
      </b-field>
      <div class="pt-8">
        <b-button
          icon-pack="far"
          icon-left="key"
          class="btn"
          :loading="loading"
          @click="changePassword"
          expanded
        >
          Change Password
        </b-button>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 ">
      <b-message
        type="is-success"
        icon-pack="far"
        class=" w-96"
        has-icon
        v-model="isSuccess"
        :auto-close="autoClose"
      >
        <span class="text-xl pt-5">Password Changed </span>
      </b-message>
      <div class="absolute bottom-4 right-4 ">
        <b-message
          type="is-danger"
          icon-pack="far"
          class=" w-96"
          has-icon
          v-model="isDanger"
          :auto-close="autoClose"
        >
          <span class="text-xl pt-5">Password Not Changed </span>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from "../firebase";
export default {
  name: "ChangePassword",
  data() {
    return {
      newPassword: "",
      isSuccess: false,
      isDanger: false,
      autoClose: true,
      loading: false,
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      const user = firebase.auth.currentUser;
      user
        .updatePassword(this.newPassword)
        .then(() => {
          // Update successful.
          console.log("Password Change");
          this.newPassword = "";
          this.isSuccess = true;
          this.loading = false;
          this.$store.dispatch("logout");
        })
        .catch((error) => {
          // An error ocurred
          console.log("Password Not Changed" + error);
          this.isDanger = true;
          this.loading = false;
        });
    },
  },
};
</script>

